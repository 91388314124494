import { Company } from '../models/Company';
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import Form from './Form';
import { useApiPost, useApiPut } from '../hooks/useApi';
import NumberField from './NumberField';
import TextField from './TextField';
import SelectField from './SelectField';
import Button from './Button';
import { useToast } from 'hooks/useToast';

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  company?: Company;
};
const ModalEditAndCreateCompany = ({
  open,
  onClose,
  onSave,
  company
}: Props) => {
  const { addToast } = useToast();
  const methods = useForm({
    defaultValues: {
      vehicles_parallels: 1,
      delivery_minutes: 5,
      ...company,
      available_wash_time: company?.available_wash_time ?? true ? 'yes' : 'no',
      customer_waiting: company?.customer_waiting ?? true ? 'yes' : 'no'
    }
  });
  const { mutate: mutateCreate, isPending: isCreating } = useApiPost(
    '/companies'
  );
  const { mutate: mutateUpdate, isPending: isUpdating } = useApiPut(
    '/companies',
    company?.id?.toString()!
  );
  const isLoading = isCreating || isUpdating;
  const handleSubmit = useCallback(
    ({ available_wash_time, customer_waiting, ...data }: any) => {
      const mutate = company?.id ? mutateUpdate : mutateCreate;
      mutate(
        {
          ...company,
          ...data,
          available_wash_time: available_wash_time === 'yes',
          customer_waiting: customer_waiting === 'yes'
        },
        {
          onSuccess: () => {
            onSave();
            addToast({
              message: company?.id
                ? 'Company updated successfully'
                : 'New company added',
              color: 'success',
              icon: 'check'
            });
          },
          onError: (response: any) => {
            const errors = response?.errors;
            if (errors) {
              Object.keys(errors).forEach((key: any) => {
                methods.setError(key, {
                  type: 'manual',
                  message: errors[key]
                });
              });
            }
          }
        }
      );
    },
    [company, mutateUpdate, mutateCreate, onSave, addToast, methods]
  );
  return (
    <Modal isOpen={open} toggle={() => onClose()} size="lg">
      <Form {...methods} onSubmit={handleSubmit}>
        <ModalHeader className="border-0">
          <span>{company?.id ? 'Edit company' : 'Add new company'}</span>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <TextField name="name" label="Name" placeholder="Insert a name" />
            </Col>
            <Col md={6}>
              <TextField
                name="phone"
                label="Phone Number"
                placeholder="Insert a phone"
              />
            </Col>
            <Col md={6}>
              <TextField
                name="address"
                label="Address"
                placeholder="Insert a address"
              />
            </Col>
            <Col md={6}>
              <TextField
                name="zip_code"
                label="Zipcode"
                placeholder="Insert a zipcode"
              />
            </Col>
          </Row>
          <p className="fw-bolder">Capacity</p>
          <Row>
            <Col md={3}>
              <NumberField
                min={1}
                name="vehicles_parallels"
                label="Multipe Vehicles"
              />
            </Col>
            <Col md={3}>
              <NumberField min={1} name="delivery_minutes" label="Time Gap" />
            </Col>
            <Col md={3}>
              <SelectField
                name="customer_waiting"
                label="Customer Waiting"
                options={['Yes', 'No'].map((x) => {
                  return {
                    label: x,
                    value: x
                  };
                })}
              />
            </Col>
            <Col md={3}>
              <SelectField
                name="available_wash_time"
                label="Next availability"
                options={['Yes', 'No'].map((x) => {
                  return {
                    label: x,
                    value: x
                  };
                })}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="border-0">
          <div className="d-flex justify-content-between align-items-center w-100">
            <Button
              type="button"
              size="lg"
              className="btn-link text-danger text-decoration-none bg-transparent"
              onClick={onClose}
              loading={isLoading}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" size="lg" loading={isLoading}>
              Save
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
export default ModalEditAndCreateCompany;
