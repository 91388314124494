import { useContext } from 'react';
import { v4 as uuid } from 'uuid';
import { ToastStoreContext } from 'contexts/toast';
import { ToastMessage } from 'types/ToastMessage';

export const useToast = () => {
  const { toasts, setToasts } = useContext(ToastStoreContext);

  const addToast = (message: ToastMessage) => {
    setToasts({ ...toasts, [uuid()]: message });
  };

  const removeToast = (id: string) => {
    const { [id]: _, ...rest } = toasts;
    setToasts(rest);
  };
  return {
    toasts,
    addToast,
    removeToast
  };
};
