import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { useController } from 'react-hook-form';

type Props = any & {
  name: string;
  label?: string;
  defaultValue?: any;
};
const DateField = ({ name, label, defaultValue, ...props }: Props) => {
  const {
    field,
    fieldState: { invalid, error }
  } = useController({ name, defaultValue });
  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <Input
        type="date"
        {...props}
        {...field}
        // defaultValue={defaultValue?.toISOString().substring(0, 10)}
        invalid={invalid}
      />
    </FormGroup>
  );
};

export default DateField;
