import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useCallback, useMemo } from 'react';
import Form from './Form';
import { useForm } from 'react-hook-form';
import DateField from './DateField';
import SelectField from './SelectField';
import Button from './Button';
import { useApiGet } from '../hooks/useApi';
import useCompany from '../hooks/useCompany';

type Props = {
  open: boolean;
  onClose: () => void;
  applyingFilters?: boolean;
  value: any;
  onApply: (filters: any) => void;
};

const ModalFormFilter = ({
  open,
  onClose,
  applyingFilters,
  value,
  onApply
}: Props) => {
  const methods = useForm({ defaultValues: value });
  const company = useCompany();
  const { data } = useApiGet(`/companies/${company?.id}/users/?type=AGENT`);
  const handleSubmit = useCallback(
    (data: any) => {
      if (data.priority === 'all') {
        delete data.priority;
      }
      if (data.status === 'all') {
        delete data.status;
      }
      if (data.delivered === 'all') {
        delete data.delivered;
      }
      if (data.agent_dispatched === 'all') {
        delete data.agent_dispatched;
      }
      onApply(data);
    },
    [onApply]
  );
  const agentsChoices = useMemo(() => {
    if (!data) return [];
    return data.map((x: any) => {
      return {
        label: x.first_name + ' ' + x.last_name ?? '',
        value: x.id
      };
    });
  }, [data]);
  return (
    <Modal isOpen={open} toggle={() => onClose()} size="lg">
      <Form {...methods} onSubmit={handleSubmit}>
        <ModalHeader>
          <span>Filters</span>
        </ModalHeader>
        <ModalBody>
          <DateField name="date" label="Date" valueDefault={value?.date} />
          <SelectField
            name="status"
            label="Status"
            valueDefault={value?.status || 'all'}
            options={[
              null,
              'Washed',
              'Not Washed',
              'Queued',
              'Not in Carwash',
              'Wash in process'
            ].map((x) => {
              return {
                label: x || 'All',
                value: x || 'all'
              };
            })}
          />
          <SelectField
            name="priority"
            label="Priority"
            valueDefault={value?.priority || 'all'}
            options={[
              null,
              'Wash and Stage',
              'Pull up to Driveway',
              'Customer is Waiting',
              'Park In Customer Parking'
            ].map((x) => {
              return {
                label: x || 'All',
                value: x || 'all'
              };
            })}
          />
          <SelectField
            name="delivered"
            label="Delivered"
            valueDefault={value?.delivered || 'No'}
            options={[null, 'Yes', 'No'].map((x) => {
              return {
                label: x || 'All',
                value: x || 'all'
              };
            })}
          />
          <SelectField
            name="agent_dispatched"
            label="Agent Dispatched"
            valueDefault={value?.agent_dispatched || 'all'}
            options={[null, ...agentsChoices].map((x) => {
              return {
                label: x?.label || 'All',
                value: x?.value || 'all'
              };
            })}
          />
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-between align-items-center w-100">
            <Button
              outline
              color="danger"
              onClick={onClose}
              type="button"
              size="lg"
              loading={applyingFilters}
            >
              Cancel
            </Button>
            <Button size="lg" color="primary" disabled={applyingFilters}>
              Apply Filters
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ModalFormFilter;
