import {
  Col,
  FormFeedback,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table
} from 'reactstrap';
import React, { useCallback } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import Form from './Form';
import { useApiGet, useApiPost, useApiPut } from '../hooks/useApi';
import TextField from './TextField';
import SelectField from './SelectField';
import Button from './Button';
import { useToast } from '../hooks/useToast';
import { User, UserType, UserTypeLabels } from '../models/User';
import PasswordField from './PasswordField';
import { Company } from '../models/Company';

const SelectMultiCompanyField = ({ error, tipo }: any) => {
  const { data: companies, isLoading } = useApiGet<Company[]>('/companies');
  const { append, remove, fields } = useFieldArray<any, any, any>({
    name: 'companies',
    keyName: '_id'
  });
  const isCompanySelected = useCallback(
    (id: string) =>
      fields.some((x) => String(x.id) === String(id)) ||
      tipo === UserType.admin,
    [fields, tipo]
  );
  if (isLoading)
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );
  return (
    <FormGroup>
      <div
        style={{ height: 200, overflow: 'auto' }}
        className="p-3 rounded-3 bg-light"
      >
        <Table className="table-light table-borderless table-striped border-2">
          <thead>
            <tr>
              <th>Company</th>
              <th style={{ width: '40px' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {companies?.map((company) => (
              <tr key={company.id}>
                <td>{company.name}</td>
                <td>
                  {isCompanySelected(company.id!) ? (
                    <Button
                      type="button"
                      className="text-white w-100 rounded-1"
                      color="danger"
                      disabled={tipo === UserType.admin}
                      onClick={() => {
                        remove(
                          fields.findIndex(
                            (x) => String(x.id) === String(company.id)
                          )
                        );
                      }}
                    >
                      Remove
                    </Button>
                  ) : (
                    <Button
                      className="text-white w-100  rounded-1"
                      type="button"
                      color="primary"
                      onClick={() => append(company)}
                    >
                      Add
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {error && (
        <FormFeedback invalid className="d-inline-block mt-3">
          {error.message}
        </FormFeedback>
      )}
    </FormGroup>
  );
};

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  user?: User;
};
const ModalEditAndCreateUser = ({ open, onClose, onSave, user }: Props) => {
  const { addToast } = useToast();
  const methods = useForm({
    defaultValues: {
      ...user,
      companies: user?.companies_details || [],
      tipo: user?.tipo || UserType.admin
    },
    reValidateMode: 'onSubmit'
  });
  const { mutate: mutateCreate, isPending: isCreating } = useApiPost('/users');
  const { mutate: mutateUpdate, isPending: isUpdating } = useApiPut(
    '/users',
    user?.id?.toString()!
  );
  const isLoading = isCreating || isUpdating;
  const companiesError = methods.formState.errors?.['companies'];
  const userTipo = methods.watch('tipo', user?.tipo || UserType.admin);
  const handleSubmit = useCallback(
    ({ available_wash_time, customer_waiting, ...data }: any, onError: any) => {
      const mutate = user?.id ? mutateUpdate : mutateCreate;
      mutate(
        {
          ...user,
          ...data,
          companies: data.companies?.map((x: any) => x.id)
        },
        {
          onSuccess: () => {
            onSave();
            addToast({
              message: user?.id
                ? 'User updated successfully'
                : 'New user added',
              color: 'success',
              icon: 'check'
            });
          },
          onError
        }
      );
    },
    [mutateCreate, mutateUpdate, onSave, addToast, user]
  );
  return (
    <Modal isOpen={open} toggle={() => onClose()} size="lg">
      <Form {...methods} onSubmit={handleSubmit}>
        <ModalHeader className="border-0">
          <span>{user?.id ? 'Edit User' : 'Add User'}</span>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <TextField
                name="first_name"
                label="First Name"
                placeholder="Insert a First Name"
              />
            </Col>
            <Col md={6}>
              <TextField
                name="last_name"
                label="Last Name"
                placeholder="Insert a last name"
              />
            </Col>
            <Col md={6}>
              <TextField
                name="email"
                label="Email"
                placeholder="Insert a email"
              />
            </Col>
            <Col md={6}>
              <SelectField
                name="tipo"
                label="Type"
                options={Object.entries(UserTypeLabels).map(
                  ([value, label]) => ({
                    value,
                    label
                  })
                )}
              />
            </Col>
            {!user?.id && (
              <>
                <Col md={6}>
                  <PasswordField
                    name="nova_senha"
                    label="Password"
                    type="password"
                    placeholder="Insert a password"
                  />
                </Col>
                <Col md={6}>
                  <PasswordField
                    name="confirme_senha"
                    label="Confirm Password"
                    type="password"
                    placeholder="Insert a confirm password"
                  />
                </Col>
              </>
            )}
          </Row>
          <SelectMultiCompanyField error={companiesError} tipo={userTipo} />
        </ModalBody>
        <ModalFooter className="border-0">
          <div className="d-flex justify-content-between align-items-center w-100">
            <Button
              type="button"
              size="lg"
              className="btn-link text-danger text-decoration-none bg-transparent"
              onClick={onClose}
              loading={isLoading}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" size="lg" loading={isLoading}>
              Save
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
export default ModalEditAndCreateUser;
