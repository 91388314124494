import { User } from './User';

export enum StatusCarWash {
  notWashed = 'not_washed',
  washInProgress = 'wash_in_process',
  notInCarWash = 'not_in_carwash',
  queued = 'queued',
  washed = 'washed'
}

export const StatusCarWashLabels = {
  [StatusCarWash.notWashed]: 'Not washed',
  [StatusCarWash.washInProgress]: 'Wash in progress',
  [StatusCarWash.notInCarWash]: 'Not in car wash',
  [StatusCarWash.queued]: 'Queued',
  [StatusCarWash.washed]: 'Washed'
};

export const StatusCarWashColorsMap = {
  [StatusCarWash.notWashed]: 'secondary',
  [StatusCarWash.washInProgress]: 'primary',
  [StatusCarWash.notInCarWash]: 'info',
  [StatusCarWash.queued]: 'warning',
  [StatusCarWash.washed]: 'success'
};

export type ICarWash = {
  id: number;
  tag: string;
  color: string;
  vehicle: string;
  note: string;
  priority: number;
  prom_time: Date;
  writer: number;
  writer_detail: User;
  status: StatusCarWash;
  priority_detail?: {
    id: number;
    item: string;
  };
  in_detail: boolean;
  delivered: boolean;
  data_cadastro: Date;
  valet_accepted_at: Date;
  user_valet: number;
  user_valet_detail: User;
  valet_accepted: boolean;
  dispatched: boolean;
  agent_dispatched: number;
  agent_dispatched_detail: User;
  dispatched_at: Date;
  delivered_at: Date;
  issue_notes: string;
  company: number;
};
