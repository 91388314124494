import React, { useMemo } from 'react';
import { Pagination as Pag, PaginationItem, PaginationLink } from 'reactstrap';

type Props = {
  onChange: (page: number) => void;
  totalPages: number;
  currentPage: number;
};
const PaginationNav = (props: Props) => {
  const { onChange, currentPage, totalPages } = props;
  const previous = useMemo(() => {
    return currentPage > 1 ? currentPage - 1 : undefined;
  }, [currentPage]);
  const next = useMemo(() => {
    return currentPage < totalPages ? currentPage + 1 : undefined;
  }, [currentPage, totalPages]);
  const onClickHandler = (event: any, page: number) => {
    event.preventDefault();
    if (page === currentPage) return;
    onChange(page);
  };
  const listPages = useMemo(() => {
    // @ts-ignore
    return [...Array(totalPages).keys()].map((x, i) => {
      const page = i + 1;
      return (
        <PaginationItem key={page} active={page === currentPage}>
          <PaginationLink
            role="button"
            onClick={(event) => onClickHandler(event, page)}
          >
            {page}
          </PaginationLink>
        </PaginationItem>
      );
    });
  }, [currentPage, totalPages, onClickHandler]);
  return (
    <Pag
      aria-label="Page navigation"
      className="mt-2 d-flex justify-content-center"
    >
      <PaginationItem>
        <PaginationLink
          first
          role="button"
          onClick={(event) => onClickHandler(event, 1)}
          disabled={currentPage === 1}
        />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink
          role="button"
          previous
          disabled={!previous}
          onClick={(event) => onClickHandler(event, previous!)}
        />
      </PaginationItem>
      {listPages}
      <PaginationItem>
        <PaginationLink
          role="button"
          next
          disabled={!next}
          onClick={(event) => onClickHandler(event, currentPage + 1)}
        />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink
          role="button"
          disabled={currentPage === totalPages}
          last
          onClick={(event) => onClickHandler(event, totalPages)}
        />
      </PaginationItem>
    </Pag>
  );
};

export default PaginationNav;
