import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

const useFieldSetErrors = (setError?: any) => {
  const formContext = useFormContext();

  return useCallback(
    (error: any) => {
      const realSetError = setError || formContext.setError;

      Object.keys(error.errors).forEach((name) =>
        realSetError(name, {
          type: 'manual',
          message: error.errors[name][0]
        })
      );
    },
    [setError, formContext?.setError]
  );
};

export default useFieldSetErrors;
