import { Card, CardBody, Spinner } from 'reactstrap';
import DonutChart from './DonutChart';
import React from 'react';
import classnames from 'classnames';

type Props = {
  title: string;
  legend?: string;
  data?: {
    positiveItem: { name: string; value: number };
    negativeItem: { name: string; value: number };
  };
};
const DashCard = ({ title, legend, data }: Props) => {
  if (!data)
    return (
      <Card className="border-0 shadow mb-3">
        <CardBody>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: '128px' }}
          >
            <Spinner />
          </div>
        </CardBody>
      </Card>
    );

  const { positiveItem, negativeItem } = data;
  return (
    <Card className="border-0 shadow mb-3 h-100">
      <CardBody className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <div>
            <DonutChart
              width={160}
              height={130}
              innerRadius={45}
              margin={6}
              data={[negativeItem, positiveItem]}
            />
          </div>
          <div>
            <h5 className="fw-bolder text-dark">{title}</h5>
            <p className={classnames('text-gray-800', { 'mb-5': !legend })}>
              {legend}
            </p>
            <div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                >
                  <circle cx="4" cy="4" r="4" fill="#0076D5" />
                </svg>
                <span className="ms-2 small text-secondary">
                  {positiveItem.name}
                </span>
                <span className="ms-1 fw-bold small">{positiveItem.value}</span>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                >
                  <circle cx="4" cy="4" r="4" fill="#F87269" />
                </svg>
                <span className="ms-2 small text-secondary">
                  {negativeItem.name}
                </span>
                <span className="ms-1 fw-bold small">{negativeItem.value}</span>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default DashCard;
