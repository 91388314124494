import { FormProvider, UseFormReturn } from 'react-hook-form';
import { PropsWithChildren, useCallback } from 'react';

type Props = PropsWithChildren<
  UseFormReturn<any> & {
    onSubmit: (data: any, onError?: (requestError: any) => void) => void;
  }
>;
const Form = ({ onSubmit, children, ...props }: Props) => {
  const { setError } = props;
  const handleApiErrors = useCallback(
    (response: any) => {
      const errors = response?.errors;
      if (errors) {
        Object.keys(errors).forEach((key: any) => {
          setError(key, {
            type: 'manual',
            message: errors[key]
          });
        });
      }
    },
    [setError]
  );
  const onSubmitWrapper = useCallback(
    (data: any) => onSubmit(data, handleApiErrors),
    [handleApiErrors, onSubmit]
  );
  return (
    <FormProvider {...props}>
      <form
        onSubmit={(e) => {
          props.clearErrors();
          props.handleSubmit(onSubmitWrapper)(e);
        }}
      >
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
