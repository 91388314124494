import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { useController } from 'react-hook-form';
import { InputProps } from 'reactstrap/types/lib/Input';

type Props = {
  name: string;
  label?: string;
  valueDefault?: any;
} & InputProps;

const HOURS = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12'
];

const TagTimeField = ({
  valueDefault,
  name,
  label,
  minutesInterval = 15
}: Props) => {
  const today = moment();
  const time = moment(
    `${today.format('hh')}:00 ${today.format('a')}`,
    'hh:m a'
  );
  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController({
    name,
    defaultValue: valueDefault || time.toDate()
  });
  const minutesIntervalArray = useMemo(() => {
    const minutes = [];
    for (let i = 0; i < 60; i += minutesInterval) {
      minutes.push(String(i).padStart(2, '0'));
    }
    return minutes;
  }, [minutesInterval]);

  const hour = moment(value).format('hh');
  const minute = moment(value).format('mm');
  const amPm = moment(value).format('a');

  const setValue = useCallback(
    (values: { h?: string; m?: string; a?: string }) => {
      const [dia] = new Date().toISOString().split('T');
      const a = values.a || amPm;
      const h = values.h || hour;
      const m = values.m || minute;
      onChange(moment(`${dia} ${h}:${m} ${a}`, 'YYYY-MM-DD hh:mm a').toDate());
    },
    [hour, minute, amPm, onChange]
  );

  return (
    <>
      <FormGroup>
        <Label className=" pb-0 mb-0">{label}</Label>
        <Row>
          <Col md="4">
            <FormGroup>
              <Label id="hours" className="small pb-0 mb-0">
                Hour
              </Label>
              <Input
                style={{ height: 38 }}
                className="py-0"
                type="select"
                invalid={!!error}
                placeholder="Hour"
                value={hour}
                onChange={({ target }) => setValue({ h: target.value })}
              >
                <option key="-1" disabled value={-1}>
                  Hour
                </option>
                {HOURS.map((x) => {
                  return (
                    <option value={x} key={x}>
                      {x}
                    </option>
                  );
                })}
              </Input>
              {error && <FormFeedback>{error.message}</FormFeedback>}
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label id="minute" className="small pb-0 mb-0">
                Minute
              </Label>
              <Input
                style={{ height: 38 }}
                className="py-0"
                type="select"
                invalid={!!error}
                placeholder="Minute"
                value={minute}
                onChange={({ target }) => setValue({ m: target.value })}
              >
                <option key="-1" disabled value={-1}>
                  Minute
                </option>
                {minutesIntervalArray.map((x) => {
                  return (
                    <option value={x} key={x}>
                      {x}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label id="ampm" className="small pb-0 mb-0">
                AM/PM
              </Label>
              <Input
                style={{ height: 38 }}
                className="py-0"
                type="select"
                invalid={!!error}
                placeholder="AM/PM"
                value={amPm}
                onChange={({ target }) => setValue({ a: target.value })}
              >
                <option key="-1" disabled value={-1}>
                  AM/PM
                </option>
                <option value="am">AM</option>
                <option value="pm">PM</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </FormGroup>
    </>
  );
};

export default TagTimeField;
