import {
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Spinner
} from 'reactstrap';
import Icon from '../components/Icon';
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useApiDelete, useApiGet } from '../hooks/useApi';
import { User, UserType } from '../models/User';
import Pagination from './Pagination';
import PaginationNav from '../components/Pagination';
import ModalEditAndCreateUser from '../components/ModalEditAndCreateUser';
import ModalNewUserPassword from '../components/ModalNewUserPassword';
import Button from '../components/Button';

const UsersPage = () => {
  const setTimeoutRef = useRef<any>(null);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [userData, setUserData] = useState<any>();
  const [userPassword, setUserPassword] = useState<User>();
  const [query, setQuery] = useState('');
  const [page, setPage] = React.useState(1);
  const { data: users, isLoading, refetch } = useApiGet<Pagination<User>>(
    '/users',
    {
      params: { page, query }
    }
  );

  const { mutate: mutateRemoveUser, isPending } = useApiDelete('/users');

  const handleRemoveUser = useCallback(
    (user: User) => {
      if (
        window.confirm(
          'Are you sure you want to remove this user? This action cannot be undone.'
        )
      ) {
        mutateRemoveUser({ id: user.id } as any, {
          onSuccess: () => {
            refetch();
          }
        });
      }
    },
    [refetch, mutateRemoveUser]
  );

  const onSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (setTimeoutRef.current) {
      clearTimeout(setTimeoutRef.current);
    }
    setTimeoutRef.current = setTimeout(() => {
      setQuery(e.target.value);
    }, 500);
  };

  return (
    <div>
      <h3 className="mb-4 d-flex justify-content-between align-items-center">
        <span style={{ maxWidth: '100px' }}>
          Users
          <small className="text-secondary fs-6">
            <br />
            {users?.count || 0} users
          </small>
        </span>
        <div className="w-75 text-end d-flex justify-content-end align-items-center">
          {showSearchInput ? (
            <InputGroup className="bg-gray-200 rounded-5 w-50 d-inline-flex">
              <InputGroupText className="bg-gray-200 border-0 text-primary">
                <Icon icon="search" local width={20} />
              </InputGroupText>
              <Input
                type="text"
                placeholder="John Doe"
                autoFocus
                className="bg-gray-200"
                onChange={onSearchInputChange}
              />
              <InputGroupText
                className="bg-gray-200 border-0"
                onClick={() => {
                  setShowSearchInput(false);
                  setQuery('');
                }}
              >
                <Icon icon="close" local width={20} />
              </InputGroupText>
            </InputGroup>
          ) : (
            <Button
              className="text-primary bg-gray-200"
              onClick={() => setShowSearchInput(true)}
            >
              <Icon icon="search" local width={20} />
            </Button>
          )}
          <Button
            color="primary"
            onClick={() => setUserData({})}
            className="px-3 py-2 ms-2"
          >
            <Icon icon="plus-white" width={20} local />
          </Button>
        </div>
      </h3>

      <Card className="border-0 vh-100">
        <CardBody>
          {isLoading && (
            <div className="text-center">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          )}
          {!users?.count && (
            <div className="h-100 d-flex flex-fill justify-content-center align-items-center">
              <div className="text-center">
                <p className="h5 fw-bolder text-primary">No users found!</p>
                <p className="text-secondary">Add a user for this workspace</p>
                <Button
                  size="lg"
                  color="primary"
                  onClick={() => setUserData({})}
                >
                  Add User
                </Button>
              </div>
            </div>
          )}
          {users?.results.map((user) => (
            <Row
              key={user.id}
              className="border-bottom py-3 d-flex align-items-center"
            >
              <Col>
                <small className="text-secondary">
                  Name
                  <br />
                </small>
                <span className="fw-bold">
                  {user.first_name} {user.last_name}
                </span>
              </Col>
              <Col>
                <small className="text-secondary">
                  Email
                  <br />
                </small>
                <span className="fw-bold">{user.email}</span>
              </Col>
              <Col>
                <small className="text-secondary">
                  Type
                  <br />
                </small>
                <span className="fw-bold">{user.tipo_display || '-'}</span>
              </Col>
              <Col>
                <small className="text-secondary">
                  Companies
                  <br />
                </small>
                <span className="fw-bold">
                  {user.tipo === UserType.admin
                    ? 'All'
                    : user.companies_details?.map((x) => x.name).join(', ') ||
                      '-'}
                </span>
              </Col>
              <Col className="text-end text-primary">
                <Button
                  className="btn btn-light text-primary me-2"
                  onClick={() => setUserPassword(user)}
                >
                  <Icon icon="key-fill" width={15} />
                </Button>

                <Button
                  className="btn btn-light text-primary me-2"
                  onClick={() => setUserData(user)}
                >
                  <Icon icon="edit" local width={15} />
                </Button>

                <Button
                  className="btn btn-light text-primary"
                  onClick={() => handleRemoveUser(user)}
                  loading={isPending}
                >
                  <Icon icon="trash" local width={15} />
                </Button>
              </Col>
            </Row>
          ))}
        </CardBody>
      </Card>
      {users && (
        <PaginationNav
          currentPage={page}
          totalPages={users.num_pages}
          onChange={setPage}
        />
      )}
      {userData && (
        <ModalEditAndCreateUser
          onClose={() => setUserData(undefined)}
          open={!!userData}
          user={userData}
          onSave={() => {
            setUserData(undefined);
            refetch();
          }}
        />
      )}
      {userPassword && (
        <ModalNewUserPassword
          open={!!userPassword}
          onClose={() => setUserPassword(undefined)}
          onSave={() => setUserPassword(undefined)}
          user={userPassword}
        />
      )}
    </div>
  );
};

export default UsersPage;
