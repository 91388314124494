import { Company } from './Company';

export enum UserType {
  admin = 'ADMIN',
  companyAdmin = 'COMPANY_ADMIN',
  valet = 'VALET',
  agent = 'AGENT',
  washer = 'WASHER'
}

export const UserTypeLabels = {
  [UserType.admin]: 'Administrator',
  [UserType.companyAdmin]: 'Company Administrator',
  [UserType.valet]: 'Valet parking',
  [UserType.agent]: 'Agent',
  [UserType.washer]: 'Washer'
};

export type User = {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  username?: string;
  tipo?: UserType;
  tipo_display?: string;
  companies_details?: Company[];
  companies?: number[];
  data_cadastro?: Date;
};
