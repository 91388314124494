import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import React, { useCallback, useEffect, useMemo } from 'react';
import Form from './Form';
import { useForm } from 'react-hook-form';
import Button from './Button';
import TextField from './TextField';
import DateField from './DateField';
import TagTimeField from './TagTimeField';
import SelectField from './SelectField';
import useUser from '../hooks/useUser';
import Icon from './Icon';
import { useApiPost } from '../hooks/useApi';
import moment from 'moment';
import useCompany from '../hooks/useCompany';
import { useToast } from '../hooks/useToast';
import { UserType } from '../models/User';
import { StatusCarWashLabels } from '../models/CarWash';

type Props = {
  open: boolean;
  onClose: () => void;
};

const ModalCreateTagForm = ({ open, onClose }: Props) => {
  const { addToast } = useToast();
  const user = useUser();
  const company = useCompany();
  const methods = useForm();
  const { mutate, isPending } = useApiPost('/carwash');

  useEffect(() => {
    if (open) {
      methods.reset({
        date: moment().format('YYYY-MM-DD'),
        delivered: 'false',
        in_detail: 'false'
      });
    }
  }, [open, methods]);

  const [statusOptions, priorityOptions] = useMemo(
    () => [
      Object.entries(StatusCarWashLabels).map(([key, value]) => ({
        value: key,
        label: value
      })),
      company?.items_priority
        ?.filter((x) => x.active)
        .map((x) => ({
          value: x.id!,
          label: x.item!
        })) || []
    ],
    [company]
  );
  const onSubmitHandler = useCallback(
    (formData: any, onError: any) => {
      const [, promTime] = moment(formData.prom_time).format().split('T');
      const [date] = moment(formData.date).format().split('T');

      const data = {
        ...formData,
        prom_time: moment(`${date}T${promTime}`)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ssZ'),
        in_detail: formData.in_detail === 'true',
        delivered: formData.delivered === 'true',
        company: company?.id,
        date: undefined
      };
      mutate(data, {
        onSuccess: () => {
          onClose();
          addToast({
            message: 'New tag added',
            color: 'success',
            icon: 'check'
          });
        },
        onError
      });
    },
    [mutate, addToast, onClose, company]
  );

  return (
    <Modal isOpen={open} toggle={() => onClose()} size="lg">
      <Form {...methods} onSubmit={onSubmitHandler}>
        <ModalHeader className="border-0">
          <div className="d-flex justify-content-between align-items-center w-100">
            <span>Add a Tag</span>
            <Button
              color="ligt"
              className="text-primary"
              onClick={() => onClose()}
              size="lg"
            >
              <Icon local icon="close" width={20} />
            </Button>
          </div>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={4}>
              <TextField
                name="tag"
                label="Tag"
                placeholder="Tag"
                disabled={
                  user?.tipo === UserType.washer ||
                  user?.tipo === UserType.valet
                }
              />
            </Col>
            <Col md={4}>
              <TextField
                name="color"
                label="Color"
                placeholder="Color"
                disabled={
                  user?.tipo === UserType.washer ||
                  user?.tipo === UserType.valet
                }
              />
            </Col>
            <Col md={4}>
              <TextField
                name="vehicle"
                label="Vehicle"
                placeholder="Vehicle"
                disabled={user?.tipo === UserType.washer}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <DateField name="date" label="Date" placeholder="Date" />
            </Col>
            <Col md={8}>
              <TagTimeField
                name="prom_time"
                label="Prom. Time"
                placeholder="Prom. Time"
                minutesInterval={company?.delivery_minutes}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <SelectField
                name="priority"
                options={priorityOptions}
                label="Priority"
                placeholder="Priority"
              />
            </Col>
            <Col md={4}>
              <SelectField
                name="status"
                options={statusOptions}
                label="Status"
                placeholder="Status"
              />
            </Col>
            <Col md={4}>
              <SelectField
                name="delivered"
                options={[
                  {
                    value: 'true',
                    label: 'Yes'
                  },
                  {
                    value: 'false',
                    label: 'No'
                  }
                ]}
                label="Delivered"
                placeholder="Delivered"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <SelectField
                name="in_detail"
                options={[
                  {
                    value: 'true',
                    label: 'Yes'
                  },
                  {
                    value: 'false',
                    label: 'No'
                  }
                ]}
                label="In Detail"
                placeholder="In Detail"
              />
            </Col>
            <Col md={4}>
              <TextField name="note" label="Notes" placeholder="Notes" />
            </Col>
            <Col md={4}>
              <TextField
                name="location"
                label="Location"
                placeholder="Location"
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="border-0">
          <div className="d-flex justify-content-between align-items-center w-100">
            <Button
              outline
              color="danger"
              onClick={onClose}
              type="button"
              size="lg"
              loading={isPending}
            >
              Cancel
            </Button>
            <Button size="lg" color="primary" loading={isPending}>
              Add
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ModalCreateTagForm;
