import { createContext, useState } from 'react';
import { ToastMessage } from 'types/ToastMessage';

type ToastStore = {
  toasts: { [key: string]: ToastMessage };
  setToasts: (toasts: { [key: string]: ToastMessage }) => void;
};
export const ToastStoreContext = createContext<ToastStore>({} as ToastStore);

export const ToastStoreProvider = ({ children }: any) => {
  const [toasts, setToasts] = useState<{ [key: string]: ToastMessage }>({});

  return (
    <ToastStoreContext.Provider value={{ toasts, setToasts }}>
      {children}
    </ToastStoreContext.Provider>
  );
};
