import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fnApiCall } from '../config/networking';
import { useCallback, useMemo } from 'react';
import { useToast } from './useToast';

export const useInvalidateQuery = (path: string) => {
  const queryClient = useQueryClient();

  const invalidate = useCallback(
    () => queryClient.invalidateQueries({ queryKey: [path] }),
    [queryClient, path]
  );

  return { invalidate };
};

const useApiMutation = <T = any>(
  method: string,
  url: string,
  id?: string | number,
  mutationOptions?: any
) => {
  const { invalidate } = useInvalidateQuery(url);
  const { addToast } = useToast();
  return useMutation<T>({
    mutationFn: (data: any) => {
      const pathId = id ? id : data?.id;
      const path = pathId ? `${url}/${pathId}` : url;
      return fnApiCall(path, addToast, { method: method, data });
    },
    onSuccess: async () => {
      mutationOptions?.onSuccess();
      await invalidate();
    },
    ...mutationOptions
  });
};

export const useApiGet = <T = any>(url: string, options: any = {}) => {
  const { id, params } = options;
  const { addToast } = useToast();
  const urlParams = useMemo(
    () => (params ? `?${new URLSearchParams(params)}` : ''),
    [params]
  );
  const path = (id ? `${url}/${id}` : url) + urlParams;
  return useQuery<T>({
    queryKey: [url, params],
    queryFn: ({ signal }) => fnApiCall(path, addToast, { signal }),
    refetchOnWindowFocus: false,
    retry: false,
    ...options
  });
};

export const useApiPost = <T = any>(
  url: string,
  mutationOptions: any = null
) => {
  return useApiMutation<T>('post', url, undefined, mutationOptions);
};

export const useApiPut = <T = any>(
  url: string,
  id?: string | number,
  mutationOptions?: any
) => {
  return useApiMutation<T>('put', url, id, mutationOptions);
};

export const useApiDelete = <T = any>(
  url: string,
  id?: string,
  mutationOptions: any = null
) => {
  return useApiMutation<T>('delete', url, id, mutationOptions);
};
