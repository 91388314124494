import { Card, CardBody, Col, Row } from 'reactstrap';
import React, { useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useApiPut } from '../hooks/useApi';
import { useToast } from '../hooks/useToast';
import TextField from '../components/TextField';
import Form from '../components/Form';
import Button from '../components/Button';
import SessionContext from '../contexts/session';
import PasswordField from '../components/PasswordField';

const ProfilePage = () => {
  const { user, setUser, logout } = useContext(SessionContext);
  const { addToast } = useToast();
  const personalForm = useForm({
    defaultValues: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email
    }
  });
  const passwordForm = useForm();
  const {
    mutate: personalMutate,
    isPending: personalMutateIsPending
  } = useApiPut('/profile', user?.id);
  const {
    mutate: passwordMutate,
    isPending: passwordMutateIsPending
  } = useApiPut('/profile/password', user?.id);
  const handleOnSubmitPersonalInfo = useCallback(
    (data: any, onError: any) => {
      const args = {
        last_name: data.last_name,
        first_name: data.first_name,
        email: data.email
      } as any;
      personalMutate(args, {
        onSuccess: ({ data }: any) => {
          setUser(data);
          addToast({
            color: 'success',
            message: 'Personal information updated successfully',
            icon: 'check'
          });
        },
        onError
      });
    },
    [addToast, personalMutate, setUser]
  );

  const handleOnSubmitNewPassword = useCallback(
    (data: any, onError: any) => {
      passwordMutate(data, {
        onSuccess: () => {
          addToast({
            color: 'success',
            message: 'Password updated successfully',
            icon: 'check'
          });
          setTimeout(() => logout(), 3000);
        },
        onError
      });
    },
    [addToast, passwordMutate, logout]
  );
  return (
    <div className="vh-100">
      <h3 className="mb-4 d-flex justify-content-between align-items-center">
        <span style={{ maxWidth: '100px' }}>Profile</span>
      </h3>

      <Row className="justify-content-around mt-5">
        <Col md={5}>
          <Form {...personalForm} onSubmit={handleOnSubmitPersonalInfo}>
            <Card className="border-0 shadow mb-3">
              <CardBody>
                <p className="h5 mb-4">Personal information</p>
                <TextField
                  name="first_name"
                  label="First name"
                  placeholder="Insert the First Name"
                />
                <TextField
                  name="last_name"
                  label="Last name"
                  placeholder="Insert the Last Name"
                />
                <TextField
                  name="email"
                  label="Email"
                  placeholder="Insert the Email"
                />
                <p className="text-end mt-4">
                  <Button
                    color="primary"
                    type="submit"
                    loading={personalMutateIsPending}
                    size="lg"
                  >
                    Save
                  </Button>
                </p>
              </CardBody>
            </Card>
          </Form>
        </Col>
        <Col md={5}>
          <Form {...passwordForm} onSubmit={handleOnSubmitNewPassword}>
            <Card className="border-0 shadow mb-3">
              <CardBody>
                <p className="h5 mb-4">Change Password</p>
                <PasswordField
                  name="senha"
                  label="Current Password"
                  placeholder="Insert the Current Password"
                />
                <PasswordField
                  name="nova_senha"
                  label="New Password"
                  placeholder="Insert the New Password"
                />
                <PasswordField
                  name="confirme_senha"
                  label="Confirm Password"
                  placeholder="Confirm the New Password"
                />
                <p className="text-end mt-4">
                  <Button
                    color="primary"
                    type="submit"
                    loading={passwordMutateIsPending}
                    size="lg"
                  >
                    Save
                  </Button>
                </p>
              </CardBody>
            </Card>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ProfilePage;
