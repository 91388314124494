import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { useApiGet } from '../hooks/useApi';
import { Company } from '../models/Company';
import LoginPage from './LoginPage';
import CompanyItemToggle from '../components/ComapyItemToggle';
import ModalEditAndCreateCompany from '../components/ModalEditAndCreateCompany';
import Icon from '../components/Icon';
import CompanyStatusToggle from '../components/ComapyStatusToggle';
import { StatusCarWash } from '../models/CarWash';

const CompanyPage = () => {
  const [companyToEdit, setCompanyToEdit] = useState<any>();
  const { data: companies, isLoading, refetch } = useApiGet<Company[]>(
    '/companies'
  );
  if (isLoading) {
    return <LoginPage />;
  }
  return (
    <div>
      <h3 className="mb-4 d-flex justify-content-between align-items-center">
        Company
        <Button
          color="primary"
          onClick={() => setCompanyToEdit({})}
          className="px-3 py-2"
        >
          <Icon icon="plus-white" width={20} local />
        </Button>
      </h3>
      {companies?.map((company) => (
        <Card key={company.id} className="mb-3 border-0 shadow">
          <CardBody>
            <h5 className="fw-bolder text-dark fw-bolder mb-3 d-flex justify-content-between align-items-center">
              {company.name}
              <div>
                <Button
                  color="light"
                  onClick={() => setCompanyToEdit(company)}
                  className="px-3 py-2 text-primary"
                >
                  <Icon icon="edit" width={20} local />
                </Button>
              </div>
            </h5>
            <Row>
              <Col md={2}>
                <p>
                  <span className="fw-bolder">Capacity</span>
                  <br />
                  <small className="text-secondary">
                    Multiple Vehicles <br />
                  </small>
                  <span className="fw-bold">{company.vehicles_parallels}</span>
                  <br />
                  <small className="text-secondary">
                    Time Gap
                    <br />
                  </small>
                  <span className="fw-bold">{company.delivery_minutes}</span>
                  <br />
                  <small className="text-secondary">
                    Allow Customer Waiting
                    <br />
                  </small>
                  <span className="fw-bold">
                    {company.customer_waiting ? 'Yes' : 'No'}
                  </span>
                  <br />
                  <small className="text-secondary">
                    Next available wash time
                    <br />
                  </small>
                  <span className="fw-bold">
                    {company.available_wash_time ? 'Yes' : 'No'}
                  </span>
                </p>
              </Col>
              <Col md={2}>
                <p>
                  <span className="fw-bolder">Contact</span>
                  <br />
                  <small className="text-secondary">
                    Phone Number <br />
                  </small>
                  <span className="fw-bold">{company.phone || '-'}</span>
                  <br />
                  <small className="text-secondary">
                    Address
                    <br />
                  </small>
                  <span className="fw-bold">{company.address || '-'}</span>
                  <br />
                  <small className="text-secondary">
                    Zip Code
                    <br />
                  </small>
                  <span className="fw-bold">{company.zip_code || '-'}</span>
                </p>
              </Col>
              <Col md={3}>
                <p>
                  <span className="fw-bolder">Checklist</span>
                </p>
                {company.items_check_list?.map((item) => (
                  <CompanyItemToggle
                    key={item.id}
                    {...item}
                    urlToggle={`/items-check-list/${company.id}`}
                    onSave={refetch}
                  />
                ))}
                <CompanyItemToggle
                  onSave={refetch}
                  urlToggle={`/items-check-list/${company.id}`}
                  createButton="Add Item"
                />
              </Col>
              <Col md={2}>
                <p>
                  <span className="fw-bolder">Priority</span>
                </p>
                {company.items_priority?.map((item) => (
                  <CompanyItemToggle
                    key={item.id}
                    {...item}
                    urlToggle={`/items-priority-list/${company.id}`}
                    onSave={refetch}
                  />
                ))}
                <CompanyItemToggle
                  onSave={refetch}
                  urlToggle={`/items-priority-list/${company.id}`}
                  createButton="Add Priority"
                />
              </Col>
              <Col md={3}>
                <p>
                  <span className="fw-bolder">Status</span>
                </p>
                {Object.values(StatusCarWash).map((status) => (
                  <CompanyStatusToggle
                    key={status}
                    companyId={company.id!}
                    onSave={refetch}
                    status={status}
                  />
                ))}
              </Col>
            </Row>
          </CardBody>
        </Card>
      ))}
      {companyToEdit && (
        <ModalEditAndCreateCompany
          onSave={() => {
            refetch();
            setCompanyToEdit(undefined);
          }}
          company={companyToEdit}
          open={!!companyToEdit}
          onClose={() => setCompanyToEdit(undefined)}
        />
      )}
    </div>
  );
};

export default CompanyPage;
