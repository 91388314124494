import { PropsWithChildren } from 'react';
import { Container } from 'reactstrap';

const AuthLayout = ({ children }: PropsWithChildren) => {
  return <Container>{children}</Container>;
};

export const withAuthLayout = (Component: any) => {
  return (props: any) => {
    return (
      <AuthLayout>
        <Component {...props} />
      </AuthLayout>
    );
  };
};

export default AuthLayout;
