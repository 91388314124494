import { Alert, Button, Col, Row } from 'reactstrap';
import React, { useCallback, useMemo } from 'react';
import logo from '../assets/images/logo.png';
import Form from '../components/Form';
import { useForm } from 'react-hook-form';
import TextField from '../components/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiPost } from '../hooks/useApi';
import useFieldSetErrors from '../hooks/useFieldSetErrors';
import { Link } from 'react-router-dom';

const ForgotPasswordPage = () => {
  const [showMessage, setShowMessage] = React.useState(false);
  const schema = useMemo(() => {
    return yup
      .object({
        email: yup.string().email().required()
      })
      .required();
  }, []);

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const onError = useFieldSetErrors(methods.setError);

  const { mutate: loginMutation, isPending } = useApiPost('/recover-password');

  const onSubmitHandler = useCallback(
    (data: any) => {
      loginMutation(
        { ...data, web: true },
        { onError, onSuccess: () => setShowMessage(true) }
      );
    },
    [loginMutation, onError]
  );

  return (
    <Row className="d-flex justify-content-center align-items-center vh-100">
      <Col md={4} className="mx-auto">
        <img src={logo} alt="logo" className="img-fluid" />
        <p className="py-2 text-center">
          Enter your email address to recover your password
        </p>
        <Form onSubmit={onSubmitHandler} {...methods}>
          <TextField type="text" name="email" placeholder="Email" />
          {showMessage && (
            <Alert color="success">
              We have sent you an email with instructions to recover your
              password
            </Alert>
          )}
          <Button
            color={methods.formState.isValid ? 'primary' : 'secondary'}
            size="lg"
            className="w-100"
            disabled={isPending}
          >
            Recover Password
          </Button>
        </Form>
        <p className=" pt-4">
          <Link to={'/login'} className="text-decoration-none small">
            Go back
          </Link>
        </p>
      </Col>
    </Row>
  );
};

export default ForgotPasswordPage;
