import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import DashboardPage from '../pages/DashboardPage';
import useUser from '../hooks/useUser';
import LoginPage from '../pages/LoginPage';
import { withAuthLayout } from '../layouts/AuthLayout';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import { withDashboardLayout } from '../layouts/DashboardLayout';
import CompanyPage from '../pages/CompanyPage';
import UsersPage from '../pages/UsersPage';
import ProfilePage from '../pages/ProfilePage';

const PATHS = {
  AUTH_PAGES: {
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password'
  },
  LOGGED_PAGES: {
    DASHBOARD: '/',
    COMPANY: '/company',
    USERS: '/users',
    PROFILE: '/profile'
  }
};

const authRoutes = () => {
  return (
    <>
      <Route
        path={PATHS.AUTH_PAGES.LOGIN}
        Component={withAuthLayout(LoginPage)}
      />
      <Route
        path={PATHS.AUTH_PAGES.FORGOT_PASSWORD}
        Component={withAuthLayout(ForgotPasswordPage)}
      />
    </>
  );
};

const loggedRoutes = () => {
  return (
    <>
      <Route
        path={PATHS.LOGGED_PAGES.DASHBOARD}
        Component={withDashboardLayout(DashboardPage)}
      />
      <Route
        path={PATHS.LOGGED_PAGES.COMPANY}
        Component={withDashboardLayout(CompanyPage)}
      />
      <Route
        path={PATHS.LOGGED_PAGES.USERS}
        Component={withDashboardLayout(UsersPage)}
      />
      <Route
        path={PATHS.LOGGED_PAGES.PROFILE}
        Component={withDashboardLayout(ProfilePage)}
      />
    </>
  );
};

const AppRoutes = () => {
  const user = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      !user &&
      Object.values(PATHS.LOGGED_PAGES).includes(location.pathname)
    ) {
      navigate(PATHS.AUTH_PAGES.LOGIN);
    }
  }, [navigate, location.pathname, user]);

  return (
    <Routes>
      {authRoutes()}
      {loggedRoutes()}
    </Routes>
  );
};

export default AppRoutes;
