import { User } from '../models/User';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import { useApiDelete, useApiGet } from '../hooks/useApi';
import PageLoader from '../components/PageLoader';
import { Company } from '../models/Company';

type SessionContextType = {
  user?: User;
  setUser: (user?: User) => void;
  setCompany: (company?: Company) => void;
  logout: () => void;
  company?: Company;
};

const SessionContext = React.createContext<SessionContextType>({
  setUser: () => {},
  setCompany: () => {},
  logout: () => {}
});

export default SessionContext;

export function SessionProvider({ children }: PropsWithChildren<any>) {
  const [userChecked, setUserChecked] = React.useState(false);
  const [companyId, setCompanyId] = React.useState(0);
  const [user, setUser] = React.useState<User>();
  const { mutate } = useApiDelete('/login', '0');
  const { data, isFetched } = useApiGet('/session', {
    enabled: !user,
    retry: false
  });

  const logout = useCallback(() => {
    mutate({ web: true } as any, {
      onSuccess: () => {
        setUser(undefined);
        setCompanyId(0);
      }
    });
  }, [mutate]);

  const company = useMemo(() => user?.companies_details?.[companyId], [
    user,
    companyId
  ]);

  const setCompany = useCallback(
    (company?: Company) => {
      if (company) {
        const index = user?.companies_details?.findIndex(
          (x) => x.id === company.id
        );
        setCompanyId(index || 0);
      }
    },
    [user]
  );

  useEffect(() => {
    if (isFetched) {
      setUserChecked(true);
      if (data && !user) {
        setUser(data);
      }
    }
  }, [data, user, isFetched]);
  return (
    <SessionContext.Provider
      value={{ user, setUser, company, setCompany, logout }}
    >
      {!userChecked ? <PageLoader /> : children}
    </SessionContext.Provider>
  );
}
