import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap';
import React, { useCallback, useMemo } from 'react';
import Button from './Button';
import { useApiDelete, useApiGet, useApiPost } from '../hooks/useApi';
import Icon from './Icon';
import { StatusCarWash, StatusCarWashLabels } from '../models/CarWash';
import { UserType, UserTypeLabels } from '../models/User';

type Props = {
  open: boolean;
  onClose: () => void;
  companyId: number | string;
  status: StatusCarWash;
};

const ModalEditNotificationStatusForm = ({
  open,
  onClose,
  status,
  companyId
}: Props) => {
  const { data: statusNotifications, isLoading, refetch } = useApiGet(
    `/status-notification/${companyId}/${status}`,
    {
      enabled: open
    }
  );
  const { mutate: mutateCreate, isPending: creating } = useApiPost(
    `/status-notification/${companyId}/${status}`
  );
  const { mutate: mutateDelete, isPending: deleting } = useApiDelete(
    `/status-notification/${companyId}/${status}`
  );
  const isPending = deleting || creating;
  const options = useMemo(() => {
    return Object.entries(UserTypeLabels)
      .filter(([key]) => key !== UserType.admin)
      .map(([key, value]) => ({
        value: key,
        label: value,
        selectedId: statusNotifications?.find((i: any) => i.user_type === key)
          ?.id
      }));
  }, [statusNotifications]);

  const handleToggle = useCallback(
    ({ user_type, id }: any) => {
      if (id) {
        mutateDelete({ id } as any, {
          onSuccess: () => {
            refetch();
          },
          onError: () => {
            refetch();
          }
        });
      } else {
        mutateCreate(
          {
            user_type: user_type ? user_type : options[0].value
          } as any,
          {
            onSuccess: () => {
              refetch();
            }
          }
        );
      }
    },
    [mutateDelete, refetch, mutateCreate, options]
  );
  return (
    <Modal isOpen={open} toggle={() => onClose()}>
      <ModalHeader className="border-0" toggle={() => onClose()}>
        Notifications settings
      </ModalHeader>
      <ModalBody>
        <p>
          Define the user types that will be notified when the wash status
          changes to: <b>{StatusCarWashLabels[status]}</b>
        </p>
        <div className="text-center">{isLoading && <Spinner />}</div>
        {options?.map((item) => (
          <div
            key={item.value}
            className="d-flex justify-content-between align-items-center border-bottom"
          >
            <p className="fw-bold mb-0">{item.label}</p>
            <Button
              className="btn btn-link bg-transparent"
              disabled={isPending || isLoading}
              onClick={() =>
                handleToggle({ user_type: item.value, id: item.selectedId })
              }
            >
              {item.selectedId ? (
                <Icon icon="toggle-on" local />
              ) : (
                <Icon icon="toggle-off" local />
              )}
            </Button>
          </div>
        ))}
      </ModalBody>
      <ModalFooter className="border-0 text-end">
        <Button size="lg" color="primary" onClick={onClose}>
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEditNotificationStatusForm;
