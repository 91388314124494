import { Button as BButton, Spinner } from 'reactstrap';
import { ButtonProps } from 'reactstrap/types/lib/Button';
import React from 'react';

type Props = {
  loading?: boolean;
} & ButtonProps;

const Button = ({ loading, disabled, ...props }: Props) => {
  return (
    <BButton {...props} disabled={loading || disabled}>
      {loading && <Spinner size="sm" />} {props.children}
    </BButton>
  );
};

export default Button;
