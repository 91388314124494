import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import {
  Button,
  Collapse,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink
} from 'reactstrap';
import classnames from 'classnames';
import logo from '../assets/images/logo.png';
import Icon from '../components/Icon';
import { NavLink as Link, useNavigate } from 'react-router-dom';
import SessionContext from '../contexts/session';
import AlertPopups from '../components/AlertPopups';
import useUser from '../hooks/useUser';
import { UserType } from '../models/User';

const CompanySelectorButton = () => {
  const { setCompany, company: selectedCompany, user } = useContext(
    SessionContext
  );
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  return (
    <>
      <Button
        className={classnames(
          'btn-link w-100 btn-sm text-decoration-none text-dark fw-bold d-flex align-items-center mb-4 mt-2 px-4'
        )}
        onClick={() => setShowCompanyModal(!showCompanyModal)}
      >
        <span className="flex-fill">{selectedCompany?.name}</span>
        {user?.companies_details?.length && (
          <Icon icon="arrow-down" local width={20} />
        )}
      </Button>
      <Modal
        isOpen={showCompanyModal}
        toggle={() => setShowCompanyModal(!showCompanyModal)}
      >
        <ModalHeader>
          <span>Companies</span>
        </ModalHeader>
        <ModalBody>
          <p className="small">Select a company:</p>
          {user?.companies_details?.map((company) => (
            <Button
              key={company.id}
              className={classnames(
                'btn-lg w-100 text-decoration-none mb-2 fw-bold d-flex'
              )}
              color={
                company.id === selectedCompany?.id ? 'primary' : 'secondary'
              }
              outline
              onClick={() => {
                setCompany(company);
                setShowCompanyModal(false);
              }}
            >
              <span>{company.name}</span>
            </Button>
          ))}
        </ModalBody>
      </Modal>
    </>
  );
};

const Menu = () => {
  const navigate = useNavigate();
  const { logout } = useContext(SessionContext);
  const user = useUser();
  const onLogoutHandler = useCallback(() => {
    logout();
    navigate('/login');
  }, [logout, navigate]);
  const userIsAdmin = useMemo(
    () => user?.tipo === UserType.admin || user?.tipo === UserType.companyAdmin,
    [user]
  );
  return (
    <div className="d-flex flex-column p-3 flex-fill justify-content-between">
      <ul className="nav flex-column">
        <NavItem>
          <NavLink to="/" tag={Link}>
            <Icon icon="menu-dashboard" local /> Dashboard
          </NavLink>
        </NavItem>
        {userIsAdmin && (
          <>
            <NavItem>
              <NavLink to="/company" tag={Link}>
                <Icon icon="menu-company" local /> Company
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/users" tag={Link}>
                <Icon icon="menu-users" local /> Users
              </NavLink>
            </NavItem>
          </>
        )}
        <NavItem>
          <NavLink to="/profile" tag={Link}>
            <Icon icon="menu-profile" local /> Profile
          </NavLink>
        </NavItem>
      </ul>
      <div>
        <Button
          outline
          color="secondary"
          className="text-decoration-none fw-light d-flex align-items-center"
          onClick={onLogoutHandler}
        >
          <Icon icon="menu-logout" local /> Logout
        </Button>
      </div>
    </div>
  );
};

const Sidebar = () => {
  return (
    <>
      <Nav
        id="sidebarMenu"
        className={classnames(
          'nav-sidebar',
          'col-sm-4',
          'col-md-3',
          'col-lg-2',
          'd-md-flex',
          'd-none',
          'sidebar',
          'p-2',
          'bg-white',
          'flex-column',
          'z-2',
          'position-fixed',
          'vh-100'
        )}
      >
        <div>
          <img
            src={logo}
            style={{ width: '100%' }}
            className="mx-auto px-4 pt-3"
            alt="logo"
          />
          <CompanySelectorButton />
        </div>
        <Menu />
      </Nav>
    </>
  );
};

const DashboardLayout = ({ children }: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);

  const handClick = () => setIsOpen(!isOpen);

  return (
    <>
      <Navbar
        color="light"
        fixed="top"
        expand="md"
        light
        className="sticky-top d-md-none"
      >
        <NavbarBrand>
          <img
            src={logo}
            style={{ height: '24px' }}
            className="mx-auto"
            alt="logo"
          />
        </NavbarBrand>
        <NavbarToggler className="d-md-none" onClick={handClick} />
        <Collapse
          isOpen={isOpen}
          navbar
          className="d-md-flex flex-md-row-reverse"
        >
          <CompanySelectorButton />
          <Menu />
        </Collapse>
      </Navbar>
      <Container fluid>
        <div className="row">
          <Sidebar />
          <main
            role="main"
            className="col-sm-8 col-md-3 ms-sm-auto col-lg-10 px-md-4 bg-light pt-4"
          >
            {children}
          </main>
        </div>
      </Container>
      <AlertPopups />
    </>
  );
};

export default DashboardLayout;

export const withDashboardLayout = (Component: any) => (props: any) => {
  return (
    <DashboardLayout>
      <Component {...props} />
    </DashboardLayout>
  );
};
