import { Col, Row } from 'reactstrap';
import Icon from './Icon';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useToast } from '../hooks/useToast';
import Button from './Button';

const AlertPopups = () => {
  const mapTimeouts = useRef<any>({});
  const { toasts, removeToast } = useToast();
  const keys = useMemo(() => Object.keys(toasts || {}), [toasts]);
  useEffect(() => {
    keys.forEach((key) => {
      if (mapTimeouts.current[key]) {
        clearTimeout(mapTimeouts.current[key]);
      }
      mapTimeouts.current[key] = setTimeout(() => {
        removeToast(key);
      }, 5000);
    });
    const timeouts = mapTimeouts.current;
    return () => {
      Object.keys(timeouts).forEach((key) => {
        clearTimeout(timeouts[key]);
      });
    };
  }, [toasts, keys, removeToast]);
  const renderToast = useCallback(
    (key: string) => {
      const toast = toasts[key];
      if (!toast) return null;
      return (
        <Row
          key={key}
          className={`bg-${toast.color} px-2 py-3 text-white rounded-2 d-flex align-items-center fw-bold shadow z-3 mb-1`}
        >
          <Col md="11">{toast.message}</Col>
          <Col md="1" className="text-end" role="button">
            <Button
              className="btn-link text-decoration-none text-white"
              color="link"
              onClick={() => removeToast(key)}
            >
              <Icon icon={`${toast.icon}`} local />
            </Button>
          </Col>
        </Row>
      );
    },
    [removeToast, toasts]
  );
  if (keys.length === 0) return null;
  return (
    <div className="position-fixed bottom-0 w-75 pb-5 pe-5 end-0">
      {keys.map((key) => renderToast(key))}{' '}
    </div>
  );
};

export default AlertPopups;
