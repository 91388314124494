import { Col, Row } from 'reactstrap';
import moment from 'moment/moment';
import CarWashStatusBadge from './CarWashStatusBadge';
import Icon from './Icon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ICarWash } from '../models/CarWash';
import Button from './Button';
import useUser from '../hooks/useUser';
import { useApiPut } from '../hooks/useApi';
import { UserType } from '../models/User';

type Props = {
  carWash: ICarWash;
  onEdit: (carWash: ICarWash) => void;
};
const CarWashRow = ({ carWash: carWashParam, onEdit }: Props) => {
  const [carWash, setCarWash] = useState<ICarWash>();
  const user = useUser();

  useEffect(() => {
    setCarWash(carWashParam);
  }, [carWashParam]);

  const { mutate, isPending } = useApiPut('/carwash/', carWash?.id);

  const showDispatchButton = useMemo(() => {
    return (
      !carWash?.dispatched &&
      !carWash?.valet_accepted &&
      [UserType.companyAdmin, UserType.agent, UserType.admin].includes(
        user?.tipo!
      )
    );
  }, [carWash?.dispatched, carWash?.valet_accepted, user?.tipo]);

  const showAcceptButton = useMemo(
    () =>
      !carWash?.valet_accepted &&
      user?.tipo === UserType.valet &&
      carWash?.dispatched,
    [carWash?.dispatched, carWash?.valet_accepted, user?.tipo]
  );

  const handleMutate = useCallback(
    (data: any) => {
      mutate(
        {
          ...carWash,
          ...data
        } as any,
        {
          onSuccess: ({ data: carWashUpdated }) => {
            setCarWash(carWashUpdated);
          }
        }
      );
    },
    [mutate, carWash]
  );

  const closeToPromTime =
    moment(carWash?.prom_time).diff(moment(), 'minutes') < 30 &&
    !carWash?.delivered;

  if (!carWash) return <Row />;

  return (
    <Row
      className="border-bottom py-3 d-flex align-items-center"
      onClick={() => onEdit(carWash)}
      role="button"
    >
      <Col>
        <small className="text-secondary">
          Tag
          <br />
        </small>
        <span className="fw-bold">
          #{carWash.tag}
          {closeToPromTime && <Icon icon={'warning-red'} local width={20} />}
        </span>
      </Col>
      <Col>
        <small className="text-secondary">
          Vehicle
          <br />
        </small>
        <span className="fw-bold">{carWash.vehicle || '-'}</span>
      </Col>
      <Col>
        <small className="text-secondary">
          Agent
          <br />
        </small>
        <span className="fw-bold">
          {`${carWash.writer_detail?.first_name} ${carWash.writer_detail?.last_name}`}
        </span>
      </Col>
      <Col>
        <small className="text-secondary">
          Priority
          <br />
        </small>
        <span className="fw-bold">{carWash.priority_detail?.item || '-'}</span>
      </Col>
      <Col>
        <small className="text-secondary">
          Prom. Time
          <br />
        </small>
        <span className="fw-bold">
          {moment(carWash.prom_time).format('hh:mm a')}
        </span>
      </Col>
      <Col>
        <small className="text-secondary">
          Valet
          <br />
        </small>
        <span className="fw-bold">
          {showDispatchButton ? (
            <Button
              className="text-light"
              color="info"
              onClick={(e) => {
                e.stopPropagation();
                if (
                  window.confirm(
                    'Are you sure you want to dispatch this car wash to a valet?'
                  )
                )
                  handleMutate({ dispatched: true });
              }}
              loading={isPending}
            >
              Dispatch to valet
            </Button>
          ) : (
            carWash.user_valet_detail?.first_name ||
            (showAcceptButton ? '' : 'Waiting for a valet')
          )}
          {showAcceptButton && (
            <Button
              className="text-light"
              color="info"
              onClick={(e) => {
                e.stopPropagation();
                if (
                  window.confirm(
                    'Are you sure you want to accept this car wash?'
                  )
                )
                  handleMutate({ valet_accepted: true });
              }}
              loading={isPending}
            >
              Accept it
            </Button>
          )}
        </span>
      </Col>
      <Col>
        <small className="text-secondary">
          Status
          <br />
        </small>
        <CarWashStatusBadge status={carWash.status} />
      </Col>
      <Col className="text-end text-primary">
        <Icon icon={'arrow-right'} local width={40} />
      </Col>
    </Row>
  );
};

export default CarWashRow;
