import React, { useMemo } from 'react';
import { useController } from 'react-hook-form';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { InputProps } from 'reactstrap/types/lib/Input';

type Props = {
  name: string;
  label?: string;
  options: { value: any; label: string }[];
  valueDefault?: any;
} & InputProps;

const SelectField = ({
  name,
  options,
  label,
  placeholder,
  ...props
}: Props) => {
  const {
    field: fieldProps,
    fieldState: { error }
  } = useController({
    name,
    defaultValue: props.valueDefault || options[0]?.value
  });

  const optionsList = useMemo(() => {
    return options.map(({ value, label }) => (
      <option key={value} value={value}>
        {label}
      </option>
    ));
  }, [options, placeholder]);

  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <Input
        type="select"
        {...fieldProps}
        invalid={!!error}
        placeholder={placeholder}
      >
        {optionsList}
      </Input>
      {error && <FormFeedback>{error.message}</FormFeedback>}
    </FormGroup>
  );
};

export default SelectField;
