import { ItemConfig } from '../models/Company';
import React, { useCallback } from 'react';
import { useApiDelete, useApiPost, useApiPut } from '../hooks/useApi';
import { Button, Input, InputGroup } from 'reactstrap';
import Icon from './Icon';

type PropsToggle = ItemConfig & {
  onSave: () => void;
  urlToggle: string;
  createButton?: string;
};
const CompanyItemToggle = (props: PropsToggle) => {
  const [itemToEdit, setItemToEdit] = React.useState<string>();
  const { mutate: mutateUpdate, isPending } = useApiPut<any>(
    props.urlToggle,
    props.id
  );
  const { mutate: mutateCreate } = useApiPost<any>(props.urlToggle);
  const { mutate: mutateDelete } = useApiDelete(
    props.urlToggle,
    props.id?.toString()!
  );
  const onUpdateHandler = useCallback(() => {
    mutateUpdate(
      { ...props, active: !props.active, company: undefined } as any,
      {
        onSuccess: () => {
          props.onSave();
        }
      }
    );
  }, [mutateUpdate, props]);

  const onSaveItemHandler = useCallback(() => {
    const mutate = props.id ? mutateUpdate : mutateCreate;
    if (itemToEdit) {
      mutate({ ...props, item: itemToEdit, company: undefined } as any, {
        onSuccess: () => {
          props.onSave();
          setItemToEdit(undefined);
        }
      });
    }
  }, [mutateUpdate, mutateCreate, itemToEdit, props]);

  const onRemoveItemHandler = useCallback(() => {
    if (confirm('Are you sure would like to remove it?')) {
      mutateDelete({} as any, {
        onSuccess: () => {
          props.onSave();
          setItemToEdit(undefined);
        }
      });
    }
  }, [props, mutateDelete]);

  return (
    <div className="text-gray-800 fs-6 d-flex justify-content-between mb-0 align-items-center flex-nowrap">
      {itemToEdit !== undefined ? (
        <InputGroup>
          {props.id && (
            <Button
              className="btn btn-link bg-transparent text-danger ps-0"
              disabled={isPending}
              onClick={onRemoveItemHandler}
            >
              <Icon icon="trash" local width={18} />
            </Button>
          )}
          <Input
            invalid={!itemToEdit}
            name="item_edit"
            value={itemToEdit}
            autoFocus
            onChange={({ target }) => setItemToEdit(target.value)}
          />
          <Button
            className="btn btn-link bg-transparent text-success p-0"
            disabled={isPending}
            onClick={onSaveItemHandler}
          >
            <Icon icon="check" width={40} />
          </Button>
          <Button
            className="btn btn-link bg-transparent text-danger p-0"
            disabled={isPending}
            onClick={() => setItemToEdit(undefined)}
          >
            <Icon icon="close" local width={30} />
          </Button>
        </InputGroup>
      ) : !props.id ? (
        <p>
          <Button
            className="btn btn-link bg-transparent text-decoration-none fw-bold"
            onClick={() => setItemToEdit('')}
          >
            <Icon icon="plus-primary" local width={20} /> {props.createButton}
          </Button>
        </p>
      ) : (
        <>
          <Button
            className="btn btn-link bg-transparent text-primary p-0"
            onClick={() => setItemToEdit(props.item)}
          >
            <Icon icon="edit" local width={15} />
          </Button>
          <div className="d-inline-block p-2 w-100">
            <span>{props.item}</span>
          </div>
          <Button
            className="btn btn-link bg-transparent"
            disabled={isPending}
            onClick={onUpdateHandler}
          >
            {props.active ? (
              <Icon icon="toggle-on" local />
            ) : (
              <Icon icon="toggle-off" local />
            )}
          </Button>
        </>
      )}{' '}
    </div>
  );
};
export default CompanyItemToggle;
