import { useContext } from 'react';
import SessionContext from '../contexts/session';

const useCompany = () => {
  const { company } = useContext(SessionContext);

  return company;
};

export default useCompany;
