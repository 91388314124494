import moment from 'moment';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Row
} from 'reactstrap';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Icon from '../components/Icon';
import useUser from '../hooks/useUser';
import ModalFormFilter from '../components/ModalFormFilter';
import DashCard from '../components/DashCard';
import { useApiGet } from '../hooks/useApi';
import PageLoader from '../components/PageLoader';
import useCompany from '../hooks/useCompany';
import classnames from 'classnames';
import ModalCreateTagForm from '../components/ModalCreateTagForm';
import { ICarWash } from '../models/CarWash';
import ModalEditTagForm from '../components/ModalEditTagForm';
import CarWashRow from '../components/CarWashRow';

const DashboardPage = () => {
  const setTimeoutRef = useRef<any>(null);
  const company = useCompany();
  const [filters, setFilters] = useState<any>({
    date: moment().format('YYYY-MM-DD'),
    delivered: 'No'
  });
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showModalTag, setShowModalTag] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [tagToEdit, setTagToEdit] = useState<ICarWash>();
  const dateToFilter = useMemo(
    () =>
      moment(
        `${filters.date} ${moment().format('HH:mm:ss')}`,
        'YYYY-MM-DD HH:mm:ss'
      ).format('YYYY-MM-DDTHH:mm:ssZ'),
    [filters.date]
  );

  const { data, isLoading } = useApiGet('/carwash', {
    params: {
      ...filters,
      date: dateToFilter,
      company: company?.id || 0
    },
    retryOnMount: false,
    retry: false,
    refetchInterval: 5000
  });
  const user = useUser();
  const today = moment(filters.date);

  const onSearchInputChange = useCallback(
    (e: any) => {
      if (setTimeoutRef.current) {
        clearTimeout(setTimeoutRef.current);
      }
      setTimeoutRef.current = setTimeout(() => {
        setFilters({
          ...filters,
          query: e.target.value
        });
      }, 500);
    },
    [filters]
  );

  const { dashboard, ...request } = (data || {}) as any;

  return (
    <>
      <div
        className="position-fixed z-1 pt-5 bg-white"
        style={{
          top: 0,
          width: '100%',
          right: 0
        }}
      >
        <Container fluid>
          <Row className="d-flex align-items-center bg-white">
            <Col className="col-sm-4 col-md-3 col-lg-2"></Col>
            <Col md="3" className="bg-white">
              <p className="mt-2">
                <span className="h4 fw-bolder">Dashboard</span>
                <br />
                <small className="text-secondary">{today.format('LL')}</small>
              </p>
            </Col>
            <Col
              className={classnames('bg-white', {
                'text-end': !showSearchInput
              })}
            >
              {showSearchInput ? (
                <InputGroup className="bg-gray-200 rounded-5">
                  <InputGroupText className="bg-gray-200 border-0 text-primary">
                    <Icon icon="search" local width={20} />
                  </InputGroupText>
                  <Input
                    type="text"
                    placeholder="Porsche Cayenne White"
                    autoFocus
                    className="bg-gray-200"
                    onChange={onSearchInputChange}
                  />
                  <InputGroupText
                    className="bg-gray-200 border-0"
                    onClick={() => setShowSearchInput(false)}
                  >
                    <Icon icon="close" local width={20} />
                  </InputGroupText>
                </InputGroup>
              ) : (
                <Button
                  className="text-primary bg-gray-200"
                  onClick={() => setShowSearchInput(true)}
                >
                  <Icon icon="search" local width={20} />
                </Button>
              )}
            </Col>
            <Col sm={2} className="text-end bg-white pe-5">
              <p className="mt-2 ps-4">
                <span className="h5 fw-bolder">
                  {user?.first_name} {user?.last_name}
                </span>
                <br />
                <small className="text-secondary">{user?.tipo_display}</small>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="mt-5 pt-5" />
      {!isLoading && (
        <section className="mt-4">
          <Row>
            <Col md="12" lg="4">
              <DashCard
                title="Todays Wash"
                legend={`Valet done < ${company?.delivery_minutes}min`}
                data={{
                  positiveItem: {
                    name: 'On time',
                    value: dashboard?.on_time + dashboard?.delivery_on_time
                  },
                  negativeItem: {
                    name: 'Late Wash',
                    value: dashboard?.delayed + dashboard?.delivery_delayed
                  }
                }}
              />
            </Col>
            <Col md="12" lg="4">
              <DashCard
                title="Valet Delivery Avg"
                legend={`Avg Delivered - ${dashboard?.avg_delivery} min`}
                data={{
                  positiveItem: {
                    name: `Delivered < ${company?.delivery_minutes}min`,
                    value: dashboard?.delivery_on_time
                  },
                  negativeItem: {
                    name: `Delivered > ${company?.delivery_minutes}min`,
                    value: dashboard?.delivery_delayed
                  }
                }}
              />
            </Col>
            <Col md="12" lg="4">
              <DashCard
                title="Carwash Quality"
                data={{
                  positiveItem: {
                    name: 'Quality Ok',
                    value: dashboard?.total_delivered - dashboard?.issue_notes
                  },
                  negativeItem: {
                    name: 'Quality issue',
                    value: dashboard?.issue_notes
                  }
                }}
              />
            </Col>
          </Row>
        </section>
      )}
      {isLoading ? (
        <PageLoader />
      ) : (
        <section className={'mt-4'}>
          <Card
            className="border-0"
            style={{
              height: 'calc(100vh - 150px)'
            }}
          >
            <CardBody className="p-4">
              <Row className="d-flex align-items-center">
                <Col>
                  <p className="mt-2">
                    <span className="h4 fw-bolder">Services</span>
                    <br />
                    <small className="text-secondary">
                      Total {request.list?.length || 0} tags
                    </small>
                  </p>
                </Col>
                <Col className="text-end">
                  <Button color="gray-200" className="text-primary me-3">
                    <Icon icon="order" local width={20} />
                  </Button>
                  <Button
                    color="gray-200"
                    className="text-primary me-3"
                    onClick={() => setShowModalFilter(true)}
                  >
                    <Icon icon="filter" width={20} />
                  </Button>
                  <Button color="primary" onClick={() => setShowModalTag(true)}>
                    <Icon icon="plus-white" local width={20} />
                  </Button>
                </Col>
              </Row>
              {!request.list?.length && (
                <div className="h-100 d-flex flex-fill justify-content-center align-items-center">
                  <div className="text-center">
                    <p className="h2 fw-bolder text-primary">No tags yet!</p>
                    <p className="text-secondary">
                      We don't have any tag in registered.
                    </p>
                    <Button
                      size="lg"
                      color="primary"
                      onClick={() => setShowModalTag(true)}
                    >
                      Create Tag
                    </Button>
                  </div>
                </div>
              )}
              {request.list?.map((wash: ICarWash) => (
                <CarWashRow
                  key={wash.id}
                  carWash={wash}
                  onEdit={setTagToEdit}
                />
              ))}
            </CardBody>
          </Card>
        </section>
      )}
      <ModalCreateTagForm
        open={showModalTag}
        onClose={() => setShowModalTag(false)}
      />
      {showModalFilter && (
        <ModalFormFilter
          onApply={(filters) => {
            setFilters(filters);
            setShowModalFilter(false);
          }}
          value={filters}
          open={showModalFilter}
          onClose={() => setShowModalFilter(false)}
        />
      )}
      {tagToEdit && (
        <ModalEditTagForm
          carWash={tagToEdit}
          open={!!tagToEdit}
          onClose={() => setTagToEdit(undefined)}
        />
      )}
    </>
  );
};

export default DashboardPage;
