import React from 'react';
import AppRoutes from './navigations/routes';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { SessionProvider } from './contexts/session';
import { HashRouter } from 'react-router-dom';
import { ToastStoreProvider } from './contexts/toast';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      if (error.name === 'NetworkUnauthorizedError') {
        window.location.href = '#/login';
      }
    }
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      if (error.name === 'NetworkUnauthorizedError') {
        window.location.href = '#/login';
      }
    }
  })
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SessionProvider>
        <ToastStoreProvider>
          <HashRouter>
            <AppRoutes />
          </HashRouter>
        </ToastStoreProvider>
      </SessionProvider>
    </QueryClientProvider>
  );
}

export default App;
