import { Button, Col, Row } from 'reactstrap';
import React, { useCallback, useContext, useMemo } from 'react';
import logo from '../assets/images/logo.png';
import Form from '../components/Form';
import { useForm } from 'react-hook-form';
import TextField from '../components/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiPost } from '../hooks/useApi';
import useFieldSetErrors from '../hooks/useFieldSetErrors';
import SessionContext from '../contexts/session';
import { Link, useNavigate } from 'react-router-dom';
import PasswordField from '../components/PasswordField';

const LoginPage = () => {
  const { setUser } = useContext(SessionContext);
  const navigate = useNavigate();
  const schema = useMemo(() => {
    return yup
      .object({
        email: yup.string().email().required(),
        password: yup.string().required()
      })
      .required();
  }, []);
  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const onError = useFieldSetErrors(methods.setError);

  const { mutate: loginMutation, isPending } = useApiPost('/login');
  const onSuccess = useCallback(
    (response: any) => {
      setUser(response.data.user);
      navigate('/');
    },
    [setUser, navigate]
  );
  const onSubmitHandler = useCallback(
    (data: any) => {
      loginMutation({ ...data, web: true }, { onError, onSuccess });
    },
    [loginMutation, onError, onSuccess]
  );

  return (
    <Row className="d-flex justify-content-center align-items-center vh-100">
      <Col md={4} className="mx-auto">
        <img src={logo} alt="logo" className="img-fluid" />
        <p className="py-2 text-center">
          Enter your email address and password to login
        </p>
        <Form onSubmit={onSubmitHandler} {...methods}>
          <TextField type="text" name="email" placeholder="Email" />
          <PasswordField
            type="password"
            name="password"
            placeholder="Password"
          />
          <Button
            color={methods.formState.isValid ? 'primary' : 'secondary'}
            size="lg"
            className="w-100"
            disabled={isPending}
          >
            Sign In
          </Button>
        </Form>
        <p className=" pt-4">
          <Link to={'/forgot-password'} className="text-decoration-none small">
            Forgot my password
          </Link>
        </p>
      </Col>
    </Row>
  );
};

export default LoginPage;
