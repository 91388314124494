import icons from '../assets/icons/bootstrap-icons.svg';
import iconLocal from '../assets/icons/local-icons.svg';
import { forwardRef } from 'react';

type Props = {
  icon: string | any;
  local?: boolean;
  height?: number;
  width?: number;
  className?: string;
};

const Icon = forwardRef<SVGSVGElement, Props>(
  (
    { icon, height = 32, width = 32, local, className, ...props }: Props,
    ref
  ) => {
    return (
      <svg
        fill="currentColor"
        ref={ref}
        height={height}
        width={width}
        {...props}
      >
        <use xlinkHref={local ? `${iconLocal}#${icon}` : `${icons}#${icon}`} />
      </svg>
    );
  }
);

export default Icon;
