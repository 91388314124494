import {
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner
} from 'reactstrap';
import React, { useCallback, useEffect, useMemo } from 'react';
import Form from './Form';
import { useForm } from 'react-hook-form';
import Button from './Button';
import { ICarWash, StatusCarWashLabels } from '../models/CarWash';
import TextField from './TextField';
import DateField from './DateField';
import TagTimeField from './TagTimeField';
import SelectField from './SelectField';
import useUser from '../hooks/useUser';
import Icon from './Icon';
import { useApiGet, useApiPut } from '../hooks/useApi';
import moment from 'moment';
import useCompany from '../hooks/useCompany';
import { useToast } from '../hooks/useToast';
import ICheckList from '../models/CheckList';
import CarWashStatusBadge from './CarWashStatusBadge';
import { UserType } from '../models/User';

type Props = {
  open: boolean;
  onClose: () => void;
  carWash: ICarWash;
};

const ModalEditTagForm = ({ open, onClose, carWash }: Props) => {
  const { addToast } = useToast();
  const user = useUser();
  const company = useCompany();
  const [checkListMarked, setCheckListMarked] = React.useState<any>({});
  const [statusOptions, priorityOptions] = useMemo(
    () => [
      Object.entries(StatusCarWashLabels).map(([key, value]) => ({
        value: key,
        label: value
      })),
      company?.items_priority
        ?.filter((x) => x.active)
        .map((x) => ({
          value: x.id!,
          label: x.item!
        })) || []
    ],
    [company]
  );
  const { data: checkList, isLoading: checkListLoading, isError } = useApiGet<
    ICheckList
  >('/check-list', {
    id: carWash?.id.toString(),
    enabled: open
  });
  const methods = useForm();
  const { mutate, isPending } = useApiPut('/carwash', carWash?.id.toString());
  const { reset } = methods;
  useEffect(() => {
    if (open) {
      reset({
        ...carWash,
        status: carWash?.status || statusOptions[0]?.value,
        priority: carWash?.priority || priorityOptions[0]?.value,
        date: moment(carWash?.prom_time).format('YYYY-MM-DD'),
        'notes-check-list': isError ? '' : checkList?.note || ''
      });
      setCheckListMarked(
        isError
          ? {}
          : checkList?.items_details?.reduce(
              (acc, item) => ({ ...acc, [item.id!]: true }),
              {}
            ) || {}
      );
    }
  }, [
    open,
    checkList,
    carWash,
    statusOptions,
    priorityOptions,
    reset,
    isError
  ]);

  const onSubmitHandler = useCallback(
    (formData: any) => {
      const [, promTime] = moment(formData.prom_time).format().split('T');
      const [date] = moment(formData.date).format().split('T');
      const data = {
        ...formData,
        prom_time: moment(`${date}T${promTime}`)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ssZ'),
        in_detail: formData.in_detail === 'true',
        delivered: formData.delivered === 'true',
        company: company?.id,
        date: undefined,
        check_list_items: Object.keys(checkListMarked)
          .filter((key) => checkListMarked[key])
          .map((key) => key),
        check_list_notes: formData['notes-check-list'] || checkList?.note
      };
      mutate(data, {
        onSuccess: () => {
          onClose();
          addToast({
            message: carWash ? 'Tag updated successfully' : 'New tag added',
            color: 'success',
            icon: 'check'
          });
        },
        onError: (request: any) => {
          const errors = request?.errors;
          if (errors) {
            Object.keys(errors).forEach((key: any) => {
              methods.setError(key, {
                type: 'manual',
                message: errors[key]
              });
            });
          }
        }
      });
    },
    [
      company?.id,
      checkListMarked,
      checkList?.note,
      mutate,
      onClose,
      addToast,
      carWash,
      methods
    ]
  );

  const checkListItems = useMemo(
    () =>
      company?.items_check_list?.map(
        (item) =>
          ({
            id: item.id,
            label: item.item,
            checked: !!checkListMarked[item.id!]
          } || [])
      ),
    [company, checkListMarked]
  );

  return (
    <Modal isOpen={open} toggle={() => onClose()} size="xl">
      <Form {...methods} onSubmit={onSubmitHandler}>
        <ModalBody>
          <div className="d-flex justify-content-between align-items-center w-100">
            <span className="fw-bolder h4">#{carWash.tag}</span>
            <Button
              color="ligt"
              className="text-primary"
              onClick={() => onClose()}
              size="lg"
            >
              <Icon local icon="close" width={20} />
            </Button>
          </div>
          <p className="text-secondary">
            {carWash.vehicle}
            <br />
            {carWash.agent_dispatched_detail && (
              <b>
                {carWash.agent_dispatched_detail?.first_name}{' '}
                {carWash.agent_dispatched_detail?.last_name}
              </b>
            )}
          </p>
          <div className="d-flex justify-content-between align-items-center w-100">
            <span>Status</span>
            <CarWashStatusBadge status={carWash.status} />
          </div>
          <Row className="mt-4">
            <Col>
              <Row className="mt-4">
                <Col md={2}>
                  <TextField
                    name="vehicle"
                    label="Vehicle"
                    placeholder="Vehicle"
                    disabled={user?.tipo === UserType.washer}
                  />
                </Col>
                <Col md={2}>
                  <TextField
                    name="color"
                    label="Color"
                    placeholder="Color"
                    disabled={
                      user?.tipo === UserType.washer ||
                      user?.tipo === UserType.valet
                    }
                  />
                </Col>
                <Col md={3}>
                  <DateField name="date" label="Date" placeholder="Date" />
                </Col>
                <Col md={5}>
                  <TagTimeField
                    name="prom_time"
                    label="Prom. Time"
                    placeholder="Prom. Time"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <SelectField
                    name="priority"
                    options={priorityOptions}
                    label="Priority"
                    placeholder="Priority"
                  />
                </Col>
                <Col md={4}>
                  <SelectField
                    name="status"
                    options={statusOptions}
                    label="Status"
                    placeholder="Status"
                  />
                </Col>
                <Col md={4}>
                  <SelectField
                    name="delivered"
                    options={[
                      {
                        value: 'true',
                        label: 'Yes'
                      },
                      {
                        value: 'false',
                        label: 'No'
                      }
                    ]}
                    label="Delivered"
                    placeholder="Delivered"
                  />
                </Col>
              </Row>
              <div>
                <p className="text-secondary fw-bold">Attached files</p>
                {checkListLoading ? (
                  <div className="text-center p-3">
                    <Spinner color="primary">Loading...</Spinner>
                  </div>
                ) : (
                  <div className="d-flex flex-wrap justify-content-center align-items-center w-100">
                    {checkList?.pictures_details?.map((pic) => (
                      <div key={pic.id} className="p-2">
                        <img
                          src={pic.file}
                          alt={`img_${pic.id}`}
                          width="40"
                          height="40"
                        />
                      </div>
                    ))}
                    {(!checkList ||
                      checkList?.pictures_details?.length === 0) && (
                      <p className="small text-secondary">No files attached</p>
                    )}
                  </div>
                )}
              </div>
              <Row>
                <Col md={6}>
                  <TextField
                    name="location"
                    label="Location"
                    placeholder="Location"
                  />
                </Col>
                <Col md={6}>
                  <TextField name="note" label="Notes" placeholder="Notes" />
                </Col>
              </Row>
            </Col>
            <Col sm="3" className="bg-light py-2 rounded">
              <div>
                <p className="text-secondary fw-bold">Check-List</p>
                {checkListLoading ? (
                  <div className="text-center p-3">
                    <Spinner color="primary">Loading...</Spinner>
                  </div>
                ) : (
                  <div className="w-100 py-2">
                    {checkListItems?.map((item) => (
                      <div className="d-flex justify-content-between align-items-center">
                        <Label check for={`check-item-${item.id}`}>
                          {item.label}
                        </Label>
                        <FormGroup switch className="d-inline-block">
                          <Input
                            type="switch"
                            role="switch"
                            checked={item.checked}
                            name={`check-item-${item.id}`}
                            id={`check-item-${item.id}`}
                            defaultValue={checkList?.note}
                            onChange={() =>
                              setCheckListMarked((prev: any) => ({
                                ...prev,
                                [item.id!]: !item.checked
                              }))
                            }
                          />
                        </FormGroup>
                      </div>
                    ))}
                    {(!checkListItems || checkListItems.length === 0) && (
                      <p className="small text-secondary">
                        No check-list items
                      </p>
                    )}
                    <br />
                    <TextField
                      type="textarea"
                      name="notes-check-list"
                      label="Note quality control"
                      placeholder="Type here the notes of quality control"
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="border-0">
          <div className="d-flex justify-content-between align-items-center w-100">
            <Button
              outline
              color="danger"
              onClick={onClose}
              type="button"
              size="lg"
              loading={isPending}
            >
              Cancel
            </Button>
            <Button size="lg" color="primary" loading={isPending}>
              Save
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
export default ModalEditTagForm;
