import React from 'react';
import { Button } from 'reactstrap';
import Icon from './Icon';
import ModalEditNotificationStatusForm from './ModalEditNotificationStatusForm';
import classnames from 'classnames';
import {
  StatusCarWash,
  StatusCarWashColorsMap,
  StatusCarWashLabels
} from '../models/CarWash';

type PropsToggle = {
  onSave: () => void;
  companyId: number | string;
  status: StatusCarWash;
};

const CompanyStatusToggle = (props: PropsToggle) => {
  const [showConfigNotifications, setShowConfigNotification] = React.useState(
    false
  );

  return (
    <>
      <div className="text-gray-800 fs-6 d-flex justify-content-between mb-0 align-items-center flex-nowrap">
        <>
          <div className="d-inline-block text-start w-100 mx-1 px-2">
            <span>{StatusCarWashLabels[props.status]}</span>
          </div>
          <div className="w-100">
            <div className="d-inline-flex">
              <span
                className={classnames(
                  'p-2 shadow border-black border-1 border-opacity-10 rounded-circle',
                  `bg-${StatusCarWashColorsMap[props.status]}`
                )}
              />
            </div>
            <Button
              className="btn btn-link bg-transparent"
              onClick={() => setShowConfigNotification(true)}
            >
              <Icon icon="bell-fill" width={15} />
            </Button>
          </div>
        </>
      </div>
      <ModalEditNotificationStatusForm
        companyId={props.companyId}
        status={props.status}
        onClose={() => setShowConfigNotification(false)}
        open={showConfigNotifications}
      />
    </>
  );
};
export default CompanyStatusToggle;
