import React from 'react';
import { Badge } from 'reactstrap';
import {
  StatusCarWash,
  StatusCarWashColorsMap,
  StatusCarWashLabels
} from '../models/CarWash';

type Props = {
  status?: StatusCarWash;
};
const CarWashStatusBadge = ({ status }: Props) => {
  return (
    <Badge
      color={status ? StatusCarWashColorsMap[status] : 'primary'}
      className="px-3 py-2"
    >
      {status ? StatusCarWashLabels[status] : 'No status'}
    </Badge>
  );
};

export default CarWashStatusBadge;
