import { Spinner } from 'reactstrap';
import React from 'react';

const PageLoader = () => {
  return (
    <div
      className={
        'd-flex justify-content-center align-items-center w-100 vh-100'
      }
    >
      <Spinner color="primary">Loading...</Spinner>
    </div>
  );
};

export default PageLoader;
