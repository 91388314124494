import { useMemo } from 'react';
import * as d3 from 'd3';

type DataItem = {
  name: string;
  value: number;
};
type DonutChartProps = {
  width: number;
  height: number;
  innerRadius: number;
  margin: number;
  data?: [DataItem, DataItem];
};

const colors = ['#F87269', '#0076D5'];

const DonutChart = ({
  width,
  height,
  data,
  innerRadius,
  margin
}: DonutChartProps) => {
  const radius = Math.min(width, height) / 2 - margin;
  const positivePercentage = useMemo(() => {
    return data && data[1].value + data[0].value > 0
      ? Math.floor((data[1].value / (data[1].value + data[0].value)) * 100)
      : 0;
  }, [data]);
  const pie = useMemo(() => {
    const pieGenerator = d3.pie<any, DataItem>().value((d) => d.value);
    return data ? pieGenerator(data) : null;
  }, [data]);

  const arcs = useMemo(() => {
    const arcPathGenerator = d3.arc();
    return pie?.map((p) =>
      arcPathGenerator({
        innerRadius: innerRadius,
        outerRadius: radius,
        startAngle: p.startAngle,
        endAngle: p.endAngle
      })
    );
  }, [radius, pie, innerRadius]);

  if (!data) {
    return null;
  }

  return (
    <svg width={width} height={height} style={{ display: 'inline-block' }}>
      <g transform={`translate(${width / 2}, ${height / 2})`}>
        {arcs?.map((arc, i) => {
          return <path key={i} d={arc!} fill={colors[i]} />;
        })}
        {positivePercentage?.toString().length === 1 ? (
          <text y={10} x={-20} fill="#444444" className="fw-bold fs-3">
            {positivePercentage}%
          </text>
        ) : (
          <text
            x={positivePercentage?.toString().length > 2 ? -35 : -30}
            y={10}
            fill="#444444"
            className="fw-bold fs-3"
          >
            {(positivePercentage ? positivePercentage + '%' : '-').padStart(
              3,
              '0'
            )}
          </text>
        )}
      </g>
    </svg>
  );
};

export default DonutChart;
