import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import Form from './Form';
import { useApiPut } from '../hooks/useApi';
import Button from './Button';
import { useToast } from '../hooks/useToast';
import { User } from '../models/User';
import PasswordField from './PasswordField';

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  user?: User;
};
const ModalNewUserPassword = ({ open, onClose, onSave, user }: Props) => {
  const { addToast } = useToast();
  const methods = useForm();
  const { mutate: mutateUpdate, isPending: isLoading } = useApiPut(
    '/users/password',
    user?.id?.toString()!
  );

  const handleSubmit = useCallback(
    (data: any) => {
      mutateUpdate(
        {
          ...user,
          ...data,
          companies: data.companies?.map((x: any) => x.id)
        },
        {
          onSuccess: () => {
            addToast({
              message: 'User password updated successfully',
              color: 'success',
              icon: 'check'
            });
            onSave();
          },
          onError: (response: any) => {
            const errors = response?.errors;
            if (errors) {
              Object.keys(errors).forEach((key: any) => {
                methods.setError(key, {
                  type: 'manual',
                  message: errors[key]
                });
              });
            }
          }
        }
      );
    },
    [mutateUpdate, user, addToast, onSave, methods]
  );
  return (
    <Modal isOpen={open} toggle={() => onClose()} size="lg">
      <Form {...methods} onSubmit={handleSubmit}>
        <ModalHeader className="border-0">
          <span>{'Edit User Password'}</span>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <PasswordField
                name="nova_senha"
                label="Password"
                type="password"
                placeholder="Insert a password"
              />
            </Col>
            <Col md={6}>
              <PasswordField
                name="confirme_senha"
                label="Confirm Password"
                type="password"
                placeholder="Insert a confirm password"
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="border-0">
          <div className="d-flex justify-content-between align-items-center w-100">
            <Button
              type="button"
              size="lg"
              className="btn-link text-danger text-decoration-none bg-transparent"
              onClick={onClose}
              loading={isLoading}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" size="lg" loading={isLoading}>
              Save
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
export default ModalNewUserPassword;
